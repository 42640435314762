<template>
    <div class="sup_couch_manage">
        <h4 class="p-mb-3">{{initData.name}}</h4>
        <form @submit.prevent="deleteCouchDB()">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="dbName">Database Name</label>
                    <Dropdown inputId="dbName" v-model="deleteDBData.name" :options="initData.dbData" :filter="true" optionLabel="name" optionValue="name" placeholder="Select Database" />
                    <small v-if="deleteDBData.nameError" class="p-invalid text-danger">Please select a database name.</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="dbId">Document Id</label>
                    <Dropdown inputId="dbId" v-model="deleteDBData.id" :options="initData.document" optionLabel="name" optionValue="name" placeholder="Select Document" />
                    <small v-if="deleteDBData.idError" class="p-invalid text-danger">Please select a document Id.</small>
                </div>
                <div class="p-field p-col-12 p-d-flex sup_db_actions">
                    <Button icon="pi pi-trash" type="submit" label="Delete" class="p-button-danger p-mr-2" />
                    <Button icon="pi pi-undo" type="button" label="Clear" class="p-button-outlined p-button-secondary" />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { reactive } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
// import store from '../../../store';
import { apiKey } from "../../../config/constant";
import { deleteAdminCouchDB } from "../../../config/appUrls";
// import { useRoute } from "vue-router";
export default {
    name: "DbActionArea",
    props:{
        name: String,
        dbData: Array,
        document: Array
    },
    setup(props){
        //Vuex store data call fucntion
        const store = useStore();

        //Route call
        //const route = useRoute();

        const initData = reactive({
            name: props.name,
            dbData: props.dbData,
            document: props.document,
            status: null,
            message: null
        })

        const deleteDBData = reactive({
            name: '',
            nameError: false,
            id: '',
            idError: false,
        });

        const CouchDBDelete = () => {
            if (deleteDBData.name === '') {
                deleteDBData.nameError = true;
            } else {
                deleteDBData.nameError = false;
            }
            if (deleteDBData.id === '') {
                deleteDBData.idError = true;
            } else {
                deleteDBData.idError = false;
            }
            if (!deleteDBData.nameError && !deleteDBData.idError) {
                console.log(deleteDBData);
            }
        }

    const deleteCouchDB = async() => {
        try {
            const options = {
                    method: 'delete',
                    url: deleteAdminCouchDB,
                    params: {},
                    data: JSON.stringify({
                        exe: initData.name,
                        couchDb: deleteDBData.name,
                        document: deleteDBData.id
                    }),
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken}
                };
            let response = await axios(options);
            initData.status = response.data.status;
            initData.message = response.data.status === 200 ? response.data.data : response.data.message
            // console.log("ENter try ");
            // console.log("token====", store.getters.adminAuthToken);
            // const response = await axios.delete( deleteAdminCouchDB, {
            //     exe: initData.name,
            //     couchDb: deleteDBData.name,
            //     document: deleteDBData.id
            // }, {
            //     headers: {
            //         apiKey: apiKey,
            //         token: store.getters.adminAuthToken
            //     }
            // });
            // console.log("respnse is===",response);
            // console.log(response.data.message);
            // initData.status = response.data.status;
            // initData.message = response.data.status === 200 ? response.data.data : response.data.message
        } catch (error) {
            console.log(error.response);
            }
        }

        return{
            initData,
            deleteDBData,
            deleteCouchDB,
            CouchDBDelete
        }
    }
}
</script>

<style>

</style>